/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 14:00:35
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-09-05 11:36:23
 */

import us from '@/assets/js/lang/en-US'
import jp from '@/assets/js/lang/ja-JP'
import cn from '@/assets/js/lang/zh-CN'
import it from '@/assets/js/lang/it-IT'
import es from '@/assets/js/lang/es-ES'
import tw from '@/assets/js/lang/zh-TW'
import hk from '@/assets/js/lang/zh-HK'
import fr from '@/assets/js/lang/fr-FR'
import ru from '@/assets/js/lang/ru-RU'
import pt from '@/assets/js/lang/pt-PT'
import id from '@/assets/js/lang/id-ID'
import tr from '@/assets/js/lang/tr-TR'
import de from '@/assets/js/lang/de-DE'
import pto from '@/assets/js/lang/pt-PTO'
import th from '@/assets/js/lang/th-TH'
import ar from '@/assets/js/lang/ar-AR'
import el from '@/assets/js/lang/el-GR'
// 语言文件 这里引入文件并填写对应名字
export const langPattern = {
    'en-US': us,
    'ja-JP': jp,
    'zh-CN': cn,
    'it-IT': it,
    'es-ES': es,
    'zh-TW': tw,
    'zh-HK': hk,
    'fr-FR': fr,
    'ru-RU': ru,
    'pt-PT': pt,
    //印尼语 
    'id-ID':id,
    'tr-TR': tr,
    'de-DE':de,
    // 欧版葡萄牙语
    'pt-PTO':pto,
    //泰语
    'th-TH':th,
    //阿拉伯语
    'ar-AR':ar,
    //希腊语
    'el-GR':el
}

// 当前语言包
export function getCurrentLang() {
    var lang = window.localStorage.getItem('lang')
    console.log(`浏览器语言${navigator.language || navigator.userLanguage}`)
    if (lang == undefined || lang == null) { 
        lang = navigator.language || navigator.userLanguage
        if (lang !== undefined && lang !== null) { 
            const prefix = lang.slice(0, 2)
            console.log(`浏览器语言是${lang}`)
            if (prefix === 'zh') {
                if(lang === 'zh-HK'){
                    lang = 'zh-HK'
                } else if(lang === 'zh-TW'){
                    lang = 'zh-TW'
                } else {
                    lang = 'zh-CN'
                } 
            } else if (prefix === 'ja') {
                lang = 'ja-JP'
            } 
            else if (prefix === 'fr') {
                lang = 'fr-FR'
            }
            else if (prefix === 'ru') {
                lang = 'ru-RU'
            } else if (prefix === 'it') {
                lang = 'it-IT'
            } else if (prefix === 'es') {
                lang = 'es-ES'
            } else if (prefix === 'pt') {
                lang = 'pt-PT'
            }
            // 印尼语
            else if (prefix === 'id') {
               lang = 'id-ID'
            }
            // 欧版葡萄牙语
            else if (prefix === 'pto') {
               lang = 'pt-PTO'
            }
            else if (prefix === 'tr') {
                lang = 'tr-TR'
             } 
             // 德语
             else if (prefix === 'de') {
                lang = 'de-DE'
             }
             //泰语
             else if(prefix === 'th'){
                lang = 'th-TH'
             //阿拉伯语
              } else if(prefix === 'ar'){
                lang = 'ar-AR'
             }
             //希腊语
             else if(prefix === 'el'){
                lang = 'el-GR'
             }
             else { 
                lang = 'en-US'
            }
        }
    }
    // 当前语言文件
    let langObj = langPattern[lang] || us
    langObj.lang = lang
    return langObj
}

export const languageConfig = [
    {
        label: 'English',
        value: 'en-US',
        no: '+1',
    },
    {
        label: '日本語',
        value: 'ja-JP',
        no: '+81',
    },
    {
        label: '简体中文',
        value: 'zh-CN',
        no: '+86',
    },
    {
        label: '繁体(台湾)',
        value: 'zh-TW',
        no: '+886',
    },
    {
        label: '繁体(香港)',
        value: 'zh-HK',
        no: '+852',
    },
    {
        label: 'Español',
        value: 'es-ES',
        no: '+34',
    },
    {
        label: 'Italiano',
        value: 'it-IT',
        no: '+39',
    },
    {
        label: 'Français',
        value: 'fr-FR',
        no: '+33',
    },
    {
        label: 'Русский',
        value: 'ru-RU',
        no: '+7',
    },
    {
        label: 'Português Brasil',
        value: 'pt-PT',
        no: '+351',
    },
    {
        label: 'Indonesian',
        value: 'id-ID',
        no: '+62',
    },
    {
        label: 'Türkçe',
        value: 'tr-TR',
        no: '+90',
    },
    {
        label: 'Deutsch',
        value: 'de-DE',
        no: '+49',
    },
    {
        label: 'Português Portugal',
        value: 'pt-PTO',
        no: '+351',
    },
    {
        label: 'ไทย',
        value: 'th-TH',
        no: '+66',
    },
    {
        label: 'اَلْعَرَبِيَّةُ',
        value: 'ar-AR',
        no: '+971',
    },
    {
        label: 'Ελληνικά',
        value: 'el-GR',
        no: '+30',
    }
]
